import React from "react";
import Layout from "layouts/zh";
import Seo from "components/seo"
import SectionContent from 'components/SectionContent'
import PageBottomCard from 'components/PageBottomCard'
import DemoCard, { DemoCardGroup, DemoCardMobile } from 'components/Demo/DemoCard';

import * as styles from './index.module.less'

const demoList = [
  {
    icon: require('assets/images/demo/d1.svg').default,
    title: '电商 Demo',
    desc: '赋能用户生命周期运营，商品个性化分发，实现高质量业务增长。',
    buttonList: [{
      text: 'B2C 电商',
      mobileTitle: '电商 Demo',
      href: '/doEbiz'
    },{
      text: '跨境电商',
      mobileTitle: '电商 Demo',
      href: '/doCrossBorderEbiz'
    },{
      text: 'B2B 电商',
      mobileTitle: '电商 Demo',
      href: '/doB2BEbiz'
    }
]
  },
  {
    icon: require('assets/images/demo/d4.svg').default,
    title: '品牌零售 Demo',
    desc: '构建消费者数据平台（CDP），提供全域消费者数字化运营闭环解决方案。',
    buttonList: [{
      text: '点击体验',
      mobileTitle: '品牌零售 Demo',
      href: '/doNewRetail'
    }]
  },
  {
    icon: require('assets/images/demo/d6.svg').default,
    title: '在线教育 Demo',
    desc: '关注核心业务流程，浏览-选课-付费-预约-上课，实时关注关键节点转化率。',
    buttonList: [{
      text: '点击体验',
      mobileTitle: '在线教育 Demo',
      href: '/doEducation'
    }]
  },
  {
    icon: require('assets/images/demo/d11.svg').default,
    title: '文娱 Demo',
    desc: '及时发现内容爆点，从内容查看、互动传播，优化用户体验，提高用户粘性。',
    buttonList: [{
      text: '视频直播',
      mobileTitle: '文娱视频直播 Demo',
      href: '/doVideoWebcast'
    }, {
      text: '内容媒体',
      mobileTitle: '文娱内容媒体 Demo',
      href: '/doContent'
    }]
  },
  {
    icon: require('assets/images/demo/d8.svg').default,
    title: '证券 Demo',
    desc: '搭建全域全生命周期运营平台与智能财富管理营销中台，为财富管理保驾护航。',
    buttonList: [{
      text: '点击体验',
      mobileTitle: '证券 Demo',
      href: '/doSecurity'
    }]
  },
  {
    icon: require('assets/images/demo/d10.svg').default,
    title: '银行 Demo',
    desc: '构建以客户为中心的数字化运营工作流，实现数据驱动闭环，打造核心竞争力。',
    buttonList: [{
      text: '零售',
      mobileTitle: '零售 Demo',
      href: '/BankRetailSa'
    }, {
      text: '信用卡',
      mobileTitle: '信用卡 Demo',
      href: '/BankCreditCardSa'
    }, {
      text: '对公业务',
      mobileTitle: '对公业务 Demo',
      href: '/BankCorporateSa'
    }]
  },
  {
    icon: require('assets/images/demo/d20.svg').default,
    title: '融合媒体 Demo',
    desc: '多维分析用户观影偏好与使用特征，数据驱动用户活跃运营，挖掘用户付费价值。',
    buttonList: [{
      text: '智能大屏',
      mobileTitle: '融合媒体大屏 Demo',
      href: '/doIPTV'
    }, {
      text: '移动端',
      mobileTitle: '融合媒体移动端 Demo',
      href: '/doFullMediaMobile'
    }]
  },
  {
    icon: require('assets/images/demo/d14.svg').default,
    title: '社交电商 Demo',
    desc: '涵盖社交电商的核心业务场景，关注活动效果，实现用户分层，精准触达用户。',
    buttonList: [{
      text: '点击体验',
      mobileTitle: '社交电商 Demo',
      href: '/doSocialEbiz'
    }]
  },
  {
    icon: require('assets/images/demo/d7.svg').default,
    title: '游戏 Demo',
    desc: '用户分析，渠道评估，核心玩法，经济系统，用户标签，精细化运营的解决方案。',
    buttonList: [{
      text: '点击体验',
      mobileTitle: '游戏 Demo',
      href: '/doGame'
    }]
  },
  {
    icon: require('assets/images/demo/d2.svg').default,
    title: '互联网金融 Demo',
    desc: '选择优质渠道、洞察行为路径、提升用户高转化率及监控调优用户生命周期。',
    buttonList: [{
      text: '体验借款端',
      mobileTitle: '互联网金融借款 Demo',
      href: '/doLoan'
    }, {
      text: '体验理财端',
      mobileTitle: '互联网金融理财 Demo',
      href: '/doFinancing'
    }]
  },
  {
    icon: require('assets/images/demo/d3.svg').default,
    title: '企业服务 Demo',
    desc: '帮助企业实现潜客获取和管理、客户服务等流程，保障客户续约和提升NPS。',
    buttonList: [{
      text: '点击体验',
      mobileTitle: '企业服务 Demo',
      href: '/doB2B'
    }]
  },
  {
    icon: require('assets/images/demo/d13.svg').default,
    title: '房产行业 Demo',
    desc: '更高效的客户、经纪人及房源端的管理解决方案，优化供需流程，建立数据闭环。',
    buttonList: [{
      text: '点击体验',
      mobileTitle: '房产行业 Demo',
      href: '/doRealEstate'
    }]
  },
  {
    icon: require('assets/images/demo/d15.svg').default,
    title: '保险 Demo',
    desc: '以客户为中心，赋能险企增量与存量业务的数字化经营升级，实现降本增效。',
    buttonList: [{
      text: '直销平台',
      mobileTitle: '保险直销平台 Demo',
      href: '/doInsurance'
    }, {
      text: '代理人平台',
      mobileTitle: '保险代理人平台 Demo',
      href: '/doInsuranceAgent'
    }]
  },
  {
    icon: require('assets/images/demo/d16.svg').default,
    title: '汽车 Demo',
    desc: '打造全域旅程数据运营平台，驱动用户价值升级。',
    buttonList: [{
      text: '点击体验',
      mobileTitle: '汽车 Demo',
      href: '/doAutomotiveSa'
    }]
  },
  {
    icon: require('assets/images/demo/d17.svg').default,
    title: '社交 Demo',
    desc: '优化社交效率，提升社交质量，挖掘社交场景，用数据解锁社交财富密码。',
    buttonList: [{
      text: '点击体验',
      mobileTitle: '社交 Demo',
      href: '/doStrangersSocial'
    }]
  },
];

const DemoList: React.FC = (props) => {

  return (
    <Layout {...props}>
      <Seo 
        title="神策数据 | 在线大数据用户分析产品 | Demo体验"
        description="访问神策数据，在线体验神策分析的强大功能，神策分析为你提供了9种行业大数据分析Demo，欢迎在线体验"
        keywords="在线数据分析工具,数据分析Demo,神策分析,神策Demo,神策数据"
        saTitle="" 
      />
      <main>
        <SectionContent className={styles.DemoListContainer} title="行业解决方案 Demo" backgroundColor="#F8FBFD">
          <DemoCardGroup className={styles.DemoCardGroup}>
            {demoList.map(item => <DemoCard 
              icon={item.icon}
              title={item.title}
              desc={item.desc}
              buttonList={item.buttonList}
            />)}
          </DemoCardGroup>
          <DemoCardGroup className={styles.DemoCardGroupMb}>
            {demoList.map(demo => demo.buttonList.map(item => <DemoCardMobile 
              icon={demo.icon}
              title={item.mobileTitle}
              href={item.href}
            />))}
          </DemoCardGroup>
        </SectionContent>
        <PageBottomCard
          title="数据驱动，从现在开始！"
          desc="立即注册，和神策数据分析师一起开启数据驱动之旅！"
          leftButtonText="体验 Demo"
          leftButtonHref="/demo/demo.html"
          rightButtonText="预约上门演示"
          rightButtonHref="/form/parade.html"
        />
      </main>
    </Layout>
  );
};

export default DemoList;
